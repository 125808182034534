import { Service, ServiceImpl } from "./service";
import { AuthControl } from "../authControl"
import { Affiliate } from "../models/affiliate";
import { Ref, ref } from "vue";
import { ResponseJSON } from "./serviceUtils";

export interface AffiliatesService extends Service {
  populateAffiliates(): Promise<void>;
  getAffiliates(): Promise<Array<Affiliate>>;

  affiliates: Ref<Array<Affiliate> | undefined>

}

type affiliatesResponseJSON = ResponseJSON & {
  affiliates?: Array<Affiliate>
}

function affiliatesFromResponseJSON (responseJSON: affiliatesResponseJSON): Array<Affiliate> {
  return responseJSON.affiliates || [];
}

export class AffiliatesServiceImpl extends ServiceImpl implements AffiliatesService {
  public readonly affiliates = ref<Array<Affiliate>>();

  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {

    authControl.onAuthStateChanged((isSignedIn) => { 
      if (isSignedIn) {
        this.populateAffiliates();
      } else {
        this.affiliates.value = undefined;
      }
    });

    super(apiServer, authControl)
  };

  public async populateAffiliates(): Promise<void> {
    this.affiliates.value = await this.getAffiliates();
  }

  async getAffiliates(): Promise<Array<Affiliate>> {
    return await this.get(`/v1/affiliates`, affiliatesFromResponseJSON);
  }
}

