import { Service, ServiceImpl } from "./service";
import { AuthControl } from "../authControl"

export const FEEDBACK_EVENT_TYPE = "feedback";
export const ACTION_PRESENTED_EVENT_TYPE = "actionPresented";
export const ACTION_TAKEN_EVENT_TYPE = "actionTaken";
export const COOKIE_CONSENT_PRESENTED_EVENT_TYPE = "cookieConsentPresented";
export const COOKIES_ACCEPTED_EVENT_TYPE = "cookiesAccepted";

export type ActionEventParams = {
  actionId: string,
  roadtripId?: string,
  stopId?: string,
  campgroundId?: string
}

export type CookiesAcceptedEventParams = {
  strictlyNecessary: boolean, 
  functionality: boolean,
  targeting: boolean,
  tracking: boolean
}

export type FeedbackEventParams = {
  featureId: string,
  roadtripId?: string,
  stopId?: string,
  binaryFeedback?: boolean,
  reason?: string,
  otherText?: string  
}
export interface EventsService extends Service {
  feedbackEvent(params: FeedbackEventParams): Promise<void>;

  actionPresentedEvent(params: ActionEventParams): Promise<void>;

  actionTakenEvent(params: ActionEventParams): Promise<void>;

  cookieConsentPresentedEvent(): Promise<void>;
  cookiesAcceptedEvent(params: CookiesAcceptedEventParams): Promise<void>;
}

export class EventsServiceImpl extends ServiceImpl implements EventsService {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
    this.allowUnauthenticated = true;
  }
  
 
  async feedbackEvent(params: FeedbackEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: FEEDBACK_EVENT_TYPE, 
      ...params
    });
  }

  async actionPresentedEvent(params: ActionEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: ACTION_PRESENTED_EVENT_TYPE, 
      ...params
    });
  }

  async actionTakenEvent(params: ActionEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: ACTION_TAKEN_EVENT_TYPE,
      ...params
    });
  }

  async cookieConsentPresentedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: COOKIE_CONSENT_PRESENTED_EVENT_TYPE,
    });
  }

  async cookiesAcceptedEvent(params: CookiesAcceptedEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: COOKIES_ACCEPTED_EVENT_TYPE,
      ...params
    });
  }
}
