import { AuthControl } from "../authControl";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export type ExtractOperationStartResult = {
  status: string,
  authenticatedUrl?: URL
};

type extractOperationResponseJSON = ResponseJSON & {
  authenticatedUrl?: URL
}

function resultFromResponseJSON(responseJSON: extractOperationResponseJSON): ExtractOperationStartResult {
  return { 
    status: responseJSON.status || "Unknonwn status",
    authenticatedUrl: responseJSON.authenticatedUrl 
  };
}


export interface AdminOperationsService extends Service {
  startUsersExtractOperation(): Promise<ExtractOperationStartResult>;
  startRoadtripsExtractOperation(): Promise<ExtractOperationStartResult>;
  startStopsExtractOperation(): Promise<ExtractOperationStartResult>;
  startInterestsExtractOperation(): Promise<ExtractOperationStartResult>;
  startUserInterestsExtractOperation(): Promise<ExtractOperationStartResult>;
  startRoadtripsSharedWithExtractOperation(): Promise<ExtractOperationStartResult>;
  startReferralsExtractOperation(): Promise<ExtractOperationStartResult>;
  startAdsExtractOperation(): Promise<ExtractOperationStartResult>;
  startActionsExtractOperation(): Promise<ExtractOperationStartResult>;
  startAlertsExtractOperation(): Promise<ExtractOperationStartResult>;
  startCampgroundsExtractOperation(): Promise<ExtractOperationStartResult>;
  startStopTemplatesExtractOperation(): Promise<ExtractOperationStartResult>;
  startRoadtripTemplatesExtractOperation(): Promise<ExtractOperationStartResult>;
}

export class AdminOperationsServiceImpl extends ServiceImpl implements AdminOperationsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async startUsersExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "usersExtract" }, resultFromResponseJSON);
  }

  async startRoadtripsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "roadtripsExtract" }, resultFromResponseJSON);
  }

  async startStopsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "stopsExtract" }, resultFromResponseJSON);
  }

  async startInterestsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "interestsExtract" }, resultFromResponseJSON);
  }

  async startUserInterestsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "userInterestsExtract" }, resultFromResponseJSON);
  }

  async startRoadtripsSharedWithExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "roadtripsSharedWithExtract" }, resultFromResponseJSON);
  }

  async startReferralsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "referralsExtract" }, resultFromResponseJSON);
  }

  async startAdsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "adsExtract" }, resultFromResponseJSON);
  }

  async startActionsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "actionsExtract" }, resultFromResponseJSON);
  }

  async startAlertsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "alertsExtract" }, resultFromResponseJSON);
  }

  async startCampgroundsExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "campgroundsExtract" }, resultFromResponseJSON);
  }

  async startStopTemplatesExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "stopTemplatesExtract" }, resultFromResponseJSON);
  }

  async startRoadtripTemplatesExtractOperation(): Promise<ExtractOperationStartResult> {
    return await this.post(`/admin/v1/operations`, { operation: "roadtripTemplatesExtract" }, resultFromResponseJSON);
  }


};
