import { Interest } from "../models/interest";
import { AuthControl } from "../authControl"
import { Ref, ref } from "vue";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export interface InterestsService extends Service {
  populateInterests(): Promise<void>;
  getInterests(): Promise<Array<Interest>>;

  interests: Ref<Array<Interest> | undefined>
}

type interestsResponseJSON = ResponseJSON & {
  interests?: Array<Interest>
}

function interestsFromResponseJSON (responseJSON: interestsResponseJSON): Array<Interest> {
  return responseJSON.interests || [];
}

export class InterestsServiceImpl extends ServiceImpl implements InterestsService {
  public readonly interests = ref<Array<Interest>>();

  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {

    authControl.onAuthStateChanged((isSignedIn) => { 
      if (isSignedIn) {
        this.populateInterests();
      } else {
        this.interests.value = undefined;
      }
    });

    super(apiServer, authControl)

  };

  public async populateInterests(): Promise<void> {
    this.interests.value = await this.getInterests();
  }

  async getInterests(): Promise<Interest[]> {
    return await this.get(`/v1/interests`, interestsFromResponseJSON);
  }

}
