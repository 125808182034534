import { Service, ServiceImpl } from "./service";

// TODO(tjohns): More LatLng to somewhere more appropriate (model?)
export type LatLng = {lat: number, lng: number};

export type TimezoneInfo = {
  currentUtcOffsetSeconds: number,
  timezone: string
}

export interface TimezonesService extends Service {
  getTimezoneInfo(latLng: LatLng): Promise<TimezoneInfo>;
}

type TimezoneResponseJSON = {
  status: string,
} & TimezoneInfo;

function timezoneInfoFromResponseJSON (responseJSON: TimezoneResponseJSON): TimezoneInfo {
  return { 
    currentUtcOffsetSeconds: responseJSON.currentUtcOffsetSeconds, 
    timezone: responseJSON.timezone 
  };
}

export class TimezonesServiceImpl extends ServiceImpl implements TimezonesService {

  constructor(
    apiServer: string
  ) {
    super(apiServer)
  };

  async getTimezoneInfo(coordinate: LatLng): Promise<TimezoneInfo> {
    return await this.get(`/v1/timezones?latitude=${coordinate.lat}&longitude=${coordinate.lng}`, timezoneInfoFromResponseJSON);
  }
}

