import { Service, ServiceImpl } from "./service";
import { AuthControl } from "../authControl"
import { ResponseJSON } from "./serviceUtils";
import { Affiliate } from "../models/affiliate";

export type ReferralRequestOptions = {
  includeRoadtripDetails: boolean, 
  includeInterests: boolean 
};

export interface ReferralsService extends Service {
  requestReferral(affiliateId: string, options: ReferralRequestOptions): Promise<void>;
  getNextReferralAffiliate(): Promise<Affiliate>;
}

type NextRefResponseJSON = ResponseJSON & Affiliate;
function affiliateFromResponseJSON(responseJSON: NextRefResponseJSON): Affiliate {
  if (!responseJSON) throw Error('No response.');
  if (responseJSON.status != "ok") {
    throw Error(responseJSON.message);
  }
  delete responseJSON.status;
  return responseJSON;
}

export class ReferralsServiceImpl extends ServiceImpl implements ReferralsService {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async requestReferral(affiliateId: string, options: ReferralRequestOptions): Promise<void> {
    return await this.post(`/v1/referrals`, {affiliateId, options});
  }

  async getNextReferralAffiliate(): Promise<Affiliate> {
    
    return await this.get(`/v1/referrals`, affiliateFromResponseJSON);
  }

}
