import { AuthControl } from "../authControl"
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export type ShareRoadtripActionParams = {
  actionId: string, 
  roadtripId: string, 
  emailAddress: string
};

export type MakeRoundtripActionParams = {
  actionId: string, 
  roadtripId: string, 
};

export type SignUpForAlertsActionParams = {
  actionId: string, 
  stopId: string,   
}

export type TakeActionParams = ShareRoadtripActionParams
  | MakeRoundtripActionParams
  | SignUpForAlertsActionParams;

export interface ActionsService extends Service {
  startAction(): void;
  takeAction(params: TakeActionParams): Promise<void>;
  completeAction(): void;
}

type TakeActionResponseJSON = ResponseJSON & {
  actionId: string
}

function actionTakenFromResponseJSON (responseJSON: TakeActionResponseJSON): {actionId: string} {
  return { actionId: responseJSON.actionId };
}

export class ActionsServiceImpl extends ServiceImpl implements ActionsService {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)

  }

  startAction(): void {
    this.loading.value++;
  }

  completeAction(): void {
    this.loading.value--;
  }
  
  async takeAction(params: TakeActionParams): Promise<void> {
    return this.post(`/v1/actions`, params, actionTakenFromResponseJSON);
  }

}
