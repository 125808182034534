import { AuthControl } from "../authControl";
import { Campground } from "../models/campground";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export interface CampgroundsService extends Service {
  getCampground(campgroundId: string): Promise<Campground | undefined>;
}

export class CampgroundsServiceImpl extends ServiceImpl implements CampgroundsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl, true)
  };

  async getCampground(campgroundId: string): Promise<Campground> {
    return await this.get(`/v1/campgrounds/${campgroundId}`, transformCampgroundResponseJSON);
  }

};

type campgroundResponseJSON = ResponseJSON & Campground;
function transformCampgroundResponseJSON(responseJSON: campgroundResponseJSON): Campground {
  const response = {...responseJSON};
  delete response.status;
  return response;
}
