import { AuthControl } from "../authControl";
import { Campground, CampgroundProps } from "../models/campground";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export interface AdminCampgroundsService extends Service {
  getCampground(campgroundId: string): Promise<{campground: Campground, campgroundOverrides: Campground}>;
  updateCampground(campgroundId: string, campground: CampgroundProps): Promise<Campground>;
}

export class AdminCampgroundsServiceImpl extends ServiceImpl implements AdminCampgroundsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async getCampground(campgroundId: string): Promise<{campground: Campground, campgroundOverrides: Campground}> {
    return await this.get(`/admin/v1/campgrounds/${campgroundId}`, transformCampgroundResponseJSON);
  }

  async updateCampground(campgroundId: string, campground: CampgroundProps): Promise<Campground> {
    return await this.patch(`/admin/v1/campgrounds/${campgroundId}`, campground, transformUpdateResponseJSON);
  }

};

type campgroundResponseJSON = ResponseJSON & {campground: Campground, campgroundOverrides: Campground};
function transformCampgroundResponseJSON(responseJSON: campgroundResponseJSON): {campground: Campground, campgroundOverrides: Campground } {
  return { campground: responseJSON.campground, campgroundOverrides: responseJSON.campgroundOverrides }
}

type updateResponseJSON = ResponseJSON & Campground;
function transformUpdateResponseJSON(responseJSON: updateResponseJSON): Campground {
  delete responseJSON.status;
  const campground: Campground = responseJSON;

  return campground;
}
