import { AuthControl } from "../authControl";
import { Profile, ProfileSystemProps, ProfileUserProps } from "../models/profile";
import { ResponseJSON } from "./serviceUtils";
import { Service, ServiceImpl } from "./service";

export interface AdminProfilesService extends Service {
  getProfileForUser(userId: string): Promise<Profile>;
  getProfiles(limit?: number, offset?: number): Promise<Array<Profile>>;
  updateProfile(profileId: string, profile: ProfileUserProps & ProfileSystemProps): Promise<Profile>;
}

type ProfilesResponseJSON = ResponseJSON & {
  profiles?: Array<Profile>
}

function profilesFromResponseJSON(responseJSON: ProfilesResponseJSON): Array<Profile> {
  return responseJSON.profiles || [];
}

function profileFromResponseJSON (responseJSON: ProfilesResponseJSON): Profile {
  delete responseJSON.status;
  return responseJSON as Profile;
}

function profileFromArrayResponseJSON(responseJSON: ProfilesResponseJSON): Profile {
  delete responseJSON.status;
  return (responseJSON.profiles || [])[0] as Profile;
}
export class AdminProfilesServiceImpl extends ServiceImpl implements AdminProfilesService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async getProfiles(limit?: number, offset?: number): Promise<Array<Profile>> {
    const searchParams = new URLSearchParams({
      limit: limit ? String(limit) : "500",
      offset: offset ? String(offset) : "0"
    })
    return await this.get(`/admin/v1/profiles`, profilesFromResponseJSON, searchParams);
  }

  async getProfileForUser(userId: string): Promise<Profile> {
    const searchParams = new URLSearchParams({ 
      userId,
      limit: "1",
     });

    return await this.get(`/admin/v1/profiles`, profileFromArrayResponseJSON, searchParams);
  }

  async updateProfile(profileId: string, profile: ProfileUserProps & ProfileSystemProps): Promise<Profile> {
    return await this.patch(`/admin/v1/profiles/${profileId}`, {profile}, profileFromResponseJSON);
  }

};