import { AuthControl } from "../authControl";
import { Service, ServiceImpl } from "./service";

export type Ad = {
  imgUrl: string,
  link: string 
};

type AdResponseJSON = {
  status: string
} & Ad;


export type GetAdParams = {
  adId?: string,
  roadtripId?: string
};
export interface AdsService extends Service {
  getAd(params?: GetAdParams): Promise<Ad>;
}

export class AdsServiceImpl extends ServiceImpl {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl, true)
  };

  async getAd(params: GetAdParams = {}): Promise<Ad> { 

    const apiServer = this.apiServer;

    const adFromResponseJSON = function(responseJSON: AdResponseJSON): Ad {
      const { imgUrl, link } = responseJSON;

      // The redirects are on the API server, so we build an absolute URL here.
      // This is a little fragile, and I don't like it, but not sure of a better way.
      const absoluteLink = new URL(`/v1${link}`, apiServer).toString();

      return { imgUrl, link: absoluteLink };
    }

    const searchParams = new URLSearchParams();
    if (params.adId) searchParams.append("adId", params.adId);
    if (params.roadtripId) searchParams.append("roadtripId", params.roadtripId);

    return await this.get(searchParams.size ? `/v1/ads/?${searchParams}` : '/v1/ads', adFromResponseJSON);
  };


};
