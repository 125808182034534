import { AuthControl } from "../authControl";
import { Ad, AdProps } from "../models/ad";
import { ResponseJSON } from "./serviceUtils";
import { Service, ServiceImpl } from "./service";

export interface AdminAdsService extends Service {
  createAd(adParams: AdProps): Promise<Ad>;
  getAd(adId: string): Promise<Ad>;
  getAds(limit?: number, offset?: number): Promise<Array<Ad>>;
  updateAd(adId: string, ad: AdProps): Promise<Ad>;
  deleteAd(adId: string): Promise<void>;
}

type AdsResponseJSON = ResponseJSON & {
  ads?: Array<Ad>
}

type AdResponseJSON = ResponseJSON & Ad;

function adsFromResponseJSON(responseJSON: AdsResponseJSON): Array<Ad> {
  return responseJSON.ads || [];
}

function adFromResponseJSON (responseJSON: AdResponseJSON): Ad {
  delete responseJSON.status;
  return responseJSON;
}

export class AdminAdsServiceImpl extends ServiceImpl implements AdminAdsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  }
  
  async createAd(adParams: AdProps): Promise<Ad> {
    return await this.post(`/admin/v1/ads`, adParams, adFromResponseJSON);
  }

  async getAds(limit?: number, offset?: number): Promise<Array<Ad>> {
    const searchParams = new URLSearchParams({
      limit: limit ? String(limit) : "500",
      offset: offset ? String(offset) : "0"
    })
    return await this.get(`/admin/v1/ads`, adsFromResponseJSON, searchParams);
  }

  async getAd(adId: string): Promise<Ad> {
    return await this.get(`/admin/v1/ads/${adId}`, adFromResponseJSON);
  }

  async updateAd(adId: string, ad: AdProps): Promise<Ad> {
    return await this.patch(`/admin/v1/ads/${adId}`, ad, adFromResponseJSON);
  }

  async deleteAd(adId: string): Promise<void> {

    return await this.delete(`/admin/v1/ads/${adId}`);

  }

};