import { AuthControl } from "../authControl"
import { Alert, AlertUserProps } from "../models/alert";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export interface AlertsService extends Service {
  getAlerts(): Promise<Array<Alert>>;
  updateAlert(alertId: string, alertProps: AlertUserProps): Promise<Alert>;
}

type alertsResponseJSON = ResponseJSON & {
  alerts?: Array<Alert>
}

type alertResponseJSON = ResponseJSON & Alert;


function alertsFromResponseJSON (responseJSON: alertsResponseJSON): Array<Alert> {
  return responseJSON.alerts || [];
}

function alertFromResponseJSON (responseJSON: alertResponseJSON): Alert {
  delete responseJSON.status;
  return responseJSON;
}

export class AlertsServiceImpl extends ServiceImpl implements AlertsService {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)

  }

  async getAlerts(): Promise<Alert[]> {
    return await this.get(`/v1/alerts`, alertsFromResponseJSON);
  }

  async updateAlert(alertId: string, alertProps: AlertUserProps): Promise<Alert> {
    return await this.patch(`/v1/alerts/${alertId}`, alertProps, alertFromResponseJSON);
  }

}

